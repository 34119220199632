import './css/style.css'
import './css/media.css'
import './App.css';

function App() {
  return (
    <div>
      <canvas id="bg"></canvas>
        <div id="overlay"></div>
        <main>
            <div class='section'>
                <div id="gallery">
                    <div id="logo"><img src="../images/website_banner.png"/></div>
                </div>
            </div>
            <div className="marquee">
                <p>I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div className='section'>
                <div className='section-heading-large'>WHO IS SAPO?</div>
                <div id="gallery">
                    <div id="logo"><img src="../images/sapo_infowars.jpg"/></div>
                </div>
                <p>
                    Sapo the Frog is a memecoin based on the Argentine cartoon character known as "El Sapo Pepe". This character became a point of contention in a legal dispute involving Alex Jones' Infowars and Matt Furie, the creator of Pepe the Frog.
                    <br/><br/>
                    In 2018, Furie filed a copyright infringement lawsuit against Infowars for using an unlicensed image of Pepe the Frog in a poster sold between 2016 and 2018. Infowars' attorneys argued that Furie's character was based on the pre-existing Argentine cartoon "El Sapo Pepe," suggesting that if Pepe the Frog was an unauthorized derivative work, Furie might not hold valid copyright claims.
                    <br/><br/>
                    You can read all about it <a target='_blank' href='https://t.co/HCQDfoc1r4'>here</a>.
                </p>
            </div>
            <div className="marquee">
                <p>I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;I AM SAPO, THE ORIGINAL PEPE!&nbsp;&nbsp;&nbsp;&nbsp;</p>
            </div>
            <div class='section' id='footer-section'>
                <div className='section-heading-large' id='footer-heading'>JOIN THE CTO</div>
                <div  className='section-heading-large' id='ticker-text'>$SAPO</div>
                <div className='socials-container'>
                    <div className='social-item'>
                        <a target='_blank' href='https://t.me/sapo_community'><img src='../images/telegram-icon.png'/></a>
                    </div>
                    <div className='social-item'>
                        <a target='_blank' href='https://x.com/SapoTheFrogSol'><img src='../images/twitter-icon.png'/></a>
                    </div>
                    <div className='social-item'>
                        <a target='_blank' href='https://www.dextools.io/app/en/solana/pair-explorer/9s9HdEbu9yCSR76pQxvqv1jdZgKSidPGrWLAiSVRg7H1'><img src='../images/dextools-icon.png'/></a>
                    </div>
                </div>
            </div>
        </main>
      <div id="contract-address">Contract: EPeVBjdVzwrmeTkSBeKKDJxvWXNRqVezgQMMWTQ2pump</div>
      <div id="version">This token holds no inherent value or promise of financial rewards, serves no practical function and is solely intended for entertainment purposes.</div>
    </div>
  );
}

export default App;
